import {
    escapeCSSSelector,
    ObjectWithDomElementsT,
    queryAllWhoHasAttribute,
} from './utils/dom';
import { bodyScrollLock, scrollDirection } from './utils/scroll';

type TOCItemsT = {
    href: string,
    title: string
}[];

class TableOfContent {
    domElements: ObjectWithDomElementsT = {};

    linksById: {
        [id: string]: HTMLLinkElement
    } = {};

    arrayOfLinkIds: string[] = [];

    firstHeadlineId: string | null = null;

    CSSClassNames = {
        visibleState: 'table-of-contents-mobile_visible',
        openedState: 'table-of-contents-mobile_opened',
        link: 'table-of-contents-mobile__link',
        activeLink: 'table-of-contents-mobile__link_active',
    } as const;

    openedState = false;

    visibleState = false;

    updateTOCActiveHeading() {
        const domActiveLink = this.domElements.mobileList?.querySelector<HTMLLinkElement>(`.${this.CSSClassNames.activeLink}`) || null;

        if (domActiveLink && this.domElements?.activeHeading) {
            this.domElements.activeHeading.textContent = domActiveLink.textContent;
        }
    }

    setActiveLink(activeId: string) {
        if (this.linksById[activeId]?.classList.contains(this.CSSClassNames.activeLink)) {
            return;
        }

        for (const id in this.linksById) {
            if (this.linksById[id].classList.contains(this.CSSClassNames.activeLink)) {
                this.linksById[id].classList.remove(this.CSSClassNames.activeLink);
                break;
            }
        }

        this.linksById[activeId]?.classList.add(this.CSSClassNames.activeLink);
        this.updateTOCActiveHeading();
    }

    setMobileTOCVisibleState(isVisible: boolean) {
        const visibleStateClassName = this.CSSClassNames.visibleState;
        const classList = this.domElements?.mobile.classList;

        this.visibleState = isVisible;

        if (this.visibleState) {
            classList?.add(visibleStateClassName);
        } else {
            classList?.remove(visibleStateClassName);
        }
    }

    centerActiveLink() {
        const domActiveLink = this.domElements.mobileList?.querySelector<HTMLLinkElement>(`.${this.CSSClassNames.activeLink}`) || null;

        if (domActiveLink) {
            this.domElements.mobileList?.scrollTo({
                top: domActiveLink.offsetTop - 42 * 3,
            });
        }
    }

    toggleMobileTOCState = () => {
        this.openedState = !this.openedState;

        this.domElements.mobile.classList.toggle(this.CSSClassNames.openedState);

        if (this.openedState) {
            this.centerActiveLink();
            bodyScrollLock.enable();
        } else {
            bodyScrollLock.disable();
        }
    };

    headlinesObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const domHeadline = entry.target as HTMLElement;
            const headlineId = domHeadline?.getAttribute('id') || '';
            const isTileHeadline = headlineId === this.firstHeadlineId;

            if (isTileHeadline) {
                if (entry.isIntersecting) {
                    if (this.visibleState) {
                        // hide TOC if title is visible
                        this.setMobileTOCVisibleState(false);
                    }
                } else {
                    if (!this.visibleState) {
                        // show TOC if title is hidden
                        this.setMobileTOCVisibleState(true);
                    }
                }
            } else {
                if (entry.isIntersecting) {
                    this.setActiveLink(domHeadline.id);
                } else {
                    // eslint-disable-next-line max-len
                    if (this.visibleState && scrollDirection.current === scrollDirection.directions.up) {
                        // try show prev headline if current headline hidden
                        const headlineIndex = this.arrayOfLinkIds.indexOf(headlineId);

                        if (headlineIndex >= 1) {
                            const prevHeadlineId = this.arrayOfLinkIds[headlineIndex - 1];

                            this.setActiveLink(prevHeadlineId);
                        }
                    }
                }
            }
        });
    }, {
        rootMargin: '0% 0% -70% 0%',
    });

    insertTOCItemsHTMLToLayout(tocItems: TOCItemsT) {
        const itemsHTML = tocItems.map((item) => `
            <li class="table-of-contents-mobile__list-item">
                <a
                    class="${this.CSSClassNames.link}"
                    href="${item.href}"
                >${item.title}</a>
            </li>
        `).join('');

        this.domElements.mobileList?.insertAdjacentHTML('afterbegin', `
            <ol class="table-of-contents-mobile__list">${itemsHTML}</ol>
        `);

        this.domElements.mobileList?.querySelectorAll<HTMLLinkElement>(`.${this.CSSClassNames.link}`)
            .forEach((domLink) => {
                const id = domLink?.getAttribute('href')?.slice(1) || null;

                if (id) {
                    this.arrayOfLinkIds.push(id);

                    this.linksById[id] = domLink;
                }
            });

        if (tocItems.length > 9) {
            document.documentElement.style.setProperty('--table-of-contents-expanded-visible-items-count', '9.5');
        }
    }

    constructor() {
        this.domElements = queryAllWhoHasAttribute('data-js-table-of-contents');

        if (!this.domElements.desktopList) {
            return;
        }

        const domDesktopTOCItems = this.domElements.desktopList.querySelectorAll('a');

        if (domDesktopTOCItems) {
            const tocItems: TOCItemsT = [];

            domDesktopTOCItems.forEach((domTOCItem) => {
                tocItems.push({
                    href: domTOCItem.getAttribute('href') || '',
                    title: domTOCItem.text,
                });
            });

            if (tocItems.length) {
                const domArticleTitle = document.querySelector<HTMLHeadingElement>('[data-ja-title]');

                if (domArticleTitle) {
                    const id = domArticleTitle.getAttribute('id');

                    if (id) {
                        this.firstHeadlineId = id;
                    }

                    this.headlinesObserver.observe(domArticleTitle);
                }

                tocItems.forEach((tocItem) => {
                    try {
                        const domHeadline = document.querySelector(escapeCSSSelector(tocItem.href));

                        if (domHeadline) {
                            this.headlinesObserver.observe(domHeadline);
                        }
                    } catch (err) { /**/ }
                });

                this.insertTOCItemsHTMLToLayout(tocItems);

                // first headline active
                this.setActiveLink(this.arrayOfLinkIds[0]);

                this.domElements.mobile?.addEventListener('click', this.toggleMobileTOCState);
            }
        }
    }
}

// eslint-disable-next-line no-new
new TableOfContent();
