// @ts-ignore
import 'common/js/table-of-contents';
// @ts-ignore
import { escapeCSSSelector } from 'common/js/utils/dom';
// @ts-ignore
import { scrollDirection } from 'common/js/utils/scroll';

type TOCItemsDesktopT = {
    href: string,
    title: string
}[];

declare global {
    interface Window {
        globalTranslations: { [key: string]: string };
    }
}

class TableOfContentDesktop {
    arrayOfLinkIdsDesktop: string[];

    linksByIdDesktop: { [id: string]: HTMLLinkElement };

    domDesktopTOCItems: NodeListOf<Element>;

    tocWrapperElement: HTMLElement | null;

    tocNavElement: HTMLElement | null;

    tocHeaderTextElement: HTMLElement | null;

    tocHeaderElement: HTMLElement | null;

    tocContentElement: HTMLElement | null;

    tocListElement: HTMLElement | null;

    tocRegularOffsetTop: number;

    observer: IntersectionObserver;

    currentActiveId: string | null;

    HTML_CLASSES = {
        stickySidebar: 'sticky_sidebar',
        stickySidebarWithSpacing: 'sticky-sidebar_with-spacing',
        collapsed: 'table-of-contents_collapsed',
        activeLink: 'table-of-contents_active',
    } as const;

    HTML_ATTRIBUTES = {
        manuallyOpened: 'data-js-toc-manually-opened',
        manuallyClosed: 'data-js-toc-manually-closed',
    } as const;

    DEFAULT_TOC_TITLE = window.globalTranslations.table_toc_title || 'Table of Contents';

    constructor() {
        this.tocWrapperElement = document.querySelector('[data-js-toc-wrapper]');
        this.tocNavElement = document.querySelector('[data-js-toc-nav]');
        this.tocHeaderElement = document.querySelector('[data-js-toc-header]');
        this.tocHeaderTextElement = document.querySelector('[data-js-toc-header-text]');
        this.tocContentElement = document.querySelector('[data-js-toc-content]');
        this.tocListElement = document.querySelector('[data-js-toc-list]');
        this.tocRegularOffsetTop = -16;
        this.domDesktopTOCItems = document.querySelectorAll('[data-js-table-of-contents-item]');
        this.arrayOfLinkIdsDesktop = [];
        this.linksByIdDesktop = {};
        this.observer = this.getHeadlinesObserver();
        this.currentActiveId = null;
        this.addEventListeners();
        this.observeHeadlines();
        this.setTocHeight();
        this.setAlwaysOpen();

        document.documentElement.style.setProperty('--table-of-contents-expanded-visible-items-count', '12.3');
    }

    setAlwaysOpen() {
        this.tocNavElement?.toggleAttribute(this.HTML_ATTRIBUTES.manuallyOpened, true);
    }

    // eslint-disable-next-line class-methods-use-this
    isMobile() {
        return window.innerWidth < 1080;
    }

    setTocHeight() {
        if (!this.isMobile()) {
            if (this.tocContentElement && this.tocListElement) {
                this.tocContentElement.style.height = `${(this.tocListElement?.offsetHeight || 0) + 16}px`;
            }
        }
    }

    addEventListeners() {
        ['mousewheel', 'DOMMouseScroll'].forEach((eventName) => {
            document.addEventListener(eventName, () => {
                if (
                    !this.tocNavElement
                    || !this.tocWrapperElement
                    || this.tocNavElement.getAttribute(this.HTML_ATTRIBUTES.manuallyOpened) !== null
                    // eslint-disable-next-line max-len
                    || this.tocNavElement.getAttribute(this.HTML_ATTRIBUTES.manuallyClosed) !== null) {
                    return;
                }

                // eslint-disable-next-line max-len
                this.tocWrapperElement.classList.toggle(this.HTML_CLASSES.collapsed, this.tocWrapperElement.offsetTop > this.tocRegularOffsetTop);
                this.setTOCTitle(this.linksByIdDesktop[this.currentActiveId || '']?.textContent);
            });
        });

        this.tocHeaderElement?.addEventListener('click', () => {
            if (!this.tocWrapperElement || !this.tocNavElement) {
                return;
            }

            const isCollapsed = this.isTOCCollapsed();

            this.tocWrapperElement.classList.toggle(this.HTML_CLASSES.collapsed);
            // eslint-disable-next-line max-len
            this.tocWrapperElement.toggleAttribute(this.HTML_ATTRIBUTES.manuallyOpened, !isCollapsed);
            // eslint-disable-next-line max-len
            this.tocWrapperElement.toggleAttribute(this.HTML_ATTRIBUTES.manuallyClosed, isCollapsed);

            this.tocNavElement.toggleAttribute(this.HTML_ATTRIBUTES.manuallyOpened, !isCollapsed);
            this.tocNavElement.toggleAttribute(this.HTML_ATTRIBUTES.manuallyClosed, isCollapsed);

            this.setTOCTitle(this.linksByIdDesktop[this.currentActiveId || '']?.textContent);
        });
    }

    isTOCCollapsed() {
        return this.tocWrapperElement?.classList.contains(this.HTML_CLASSES.collapsed);
    }

    setTOCTitle(title) {
        if (this.tocWrapperElement && this.tocHeaderTextElement) {
            if (title && this.isTOCCollapsed()) {
                this.tocHeaderTextElement.innerHTML = title;
            } else {
                this.tocHeaderTextElement.innerHTML = this.DEFAULT_TOC_TITLE;
            }
        }
    }

    setActiveLink(activeId: string) {
        this.setTOCTitle(this.linksByIdDesktop[activeId]?.textContent);

        // eslint-disable-next-line max-len
        if (this.linksByIdDesktop[activeId]?.classList.contains(this.HTML_CLASSES.activeLink)) {
            return;
        }

        for (const id in this.linksByIdDesktop) {
            // eslint-disable-next-line max-len
            if (this.linksByIdDesktop[id].classList.contains(this.HTML_CLASSES.activeLink)) {
                this.linksByIdDesktop[id].classList.remove(this.HTML_CLASSES.activeLink);
                break;
            }
        }

        this.linksByIdDesktop[activeId]?.classList.add(this.HTML_CLASSES.activeLink);
        this.currentActiveId = activeId;

        this.scrollTocContainer();
    }

    scrollTocContainer() {
        if (!this.currentActiveId || !this.tocContentElement) {
            return;
        }

        const isLinkVisible = this.isElementVisible(this.linksByIdDesktop[this.currentActiveId]);
        const linkIndex = this.arrayOfLinkIdsDesktop.indexOf(this.currentActiveId);
        const amountOfScrolling = linkIndex > 10 ? 80 : -80;

        if (!isLinkVisible && !this.isTOCCollapsed()) {
            this.tocContentElement.scrollBy(0, amountOfScrolling);
        }
    }

    getHeadlinesObserver() {
        return new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const domHeadline = entry.target as HTMLElement;
                const headlineId = domHeadline?.getAttribute('id') || '';

                if (entry.isIntersecting) {
                    this.setActiveLink(domHeadline.id);
                } else {
                    // eslint-disable-next-line max-len
                    if (scrollDirection.current === scrollDirection.directions.up) {
                        // try show prev headline if current headline hidden
                        const headlineIndex = this.arrayOfLinkIdsDesktop.indexOf(headlineId);

                        if (headlineIndex >= 1) {
                            const prevHeadlineId = this.arrayOfLinkIdsDesktop[headlineIndex - 1];

                            this.setActiveLink(prevHeadlineId);
                        }
                    }
                }
            });
        }, {
            rootMargin: '0% 0% -70% 0%',
        });
    }

    observeHeadlines() {
        if (this.domDesktopTOCItems) {
            const tocItems: TOCItemsDesktopT = [];

            this.domDesktopTOCItems.forEach((domTOCItem) => {
                tocItems.push({
                    href: domTOCItem.getAttribute('href') || '',
                    title: domTOCItem.textContent || '',
                });

                const id = domTOCItem?.getAttribute('href')?.slice(1) || null;

                if (id) {
                    this.arrayOfLinkIdsDesktop.push(id);

                    // @ts-ignore
                    this.linksByIdDesktop[id] = domTOCItem;
                }
            });

            if (tocItems.length) {
                tocItems.forEach((tocItem) => {
                    try {
                        const domHeadline = document.querySelector(escapeCSSSelector(tocItem.href));

                        if (domHeadline) {
                            this.observer.observe(domHeadline);
                        }
                    } catch (err) { /**/ }
                });
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    isElementVisible(el) {
        const rect = el.getBoundingClientRect();
        const { top } = rect;
        const { height } = rect;
        let parentEl = el.parentNode;

        // Check if bottom of the element is off the page
        if (rect.bottom < 0) {
            return false;
        }

        // Check its within the document viewport
        if (top > document.documentElement.clientHeight) {
            return false;
        }

        do {
            const parentRect = parentEl.getBoundingClientRect();

            if (top <= parentRect.bottom === false) {
                return false;
            }

            // Check if the element is out of view due to a container scrolling
            if ((top + height) <= parentRect.top) {
                return false;
            }

            parentEl = parentEl.parentNode;
        } while (parentEl !== document.body);

        return true;
    }
}

// eslint-disable-next-line no-new
new TableOfContentDesktop();
