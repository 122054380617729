const toggleButtons = document.querySelectorAll('[data-js-header-toggle]');
const overlay = document.querySelector('[data-js-header-overlay]');
const menuOpenButton = document.querySelector('[data-js-header-menu-open]') as HTMLElement;
const menuCloseButton = document.querySelector('[data-js-header-menu-close]') as HTMLElement;
const body = document.querySelector('body');
const menu = document.querySelector('[data-js-menu]');
const headerBody = document.getElementById('header_body');
const menuItems = document.querySelectorAll('.menu__item-link');
const { innerWidth } = window;
const { clientWidth } = document.documentElement;
const desktopWidth = 1080;

toggleButtons.forEach((toggleButton) => {
    toggleButton.addEventListener('click', () => {
        const parentMenuItem = toggleButton.closest('.menu__item');
        const parentMenu = toggleButton.closest('.menu__inner-wrapper');

        if (parentMenuItem && parentMenu && window.innerWidth <= desktopWidth) {
            parentMenuItem.classList.toggle('menu__item-has-children_active');
            parentMenu.classList.toggle('menu__inner-wrapper_active');

            document.querySelectorAll('.menu__item-has-children_active').forEach((activeMenuItem) => {
                if (activeMenuItem !== parentMenuItem) {
                    activeMenuItem.classList.remove('menu__item-has-children_active');
                }
            });
        }
    });
});

const closeMenu = () => {
    menu?.classList.remove('menu_opened');
    body?.classList.remove('body-menu_opened');

    menuCloseButton?.removeAttribute('tabindex');
    menuItems.forEach((menuItem) => menuItem?.removeAttribute('tabindex'));

    document.querySelectorAll('.menu__item-has-children_active').forEach((activeMenuItem) => {
        activeMenuItem.classList.remove('menu__item-has-children_active');
    });

    menuOpenButton?.focus();

    if (body) {
        body.style.paddingRight = '';
    }

    if (window.innerWidth >= desktopWidth) {
        menu?.classList.add('menu_locked');
    }
};

const openMenu = () => {
    menu?.classList.add('menu_opened');
    body?.classList.add('body-menu_opened');
    headerBody?.removeAttribute('tabindex');
    menuCloseButton?.setAttribute('tabindex', '0');

    menuItems.forEach((menuItem) => {
        menuItem?.setAttribute('tabindex', '0');
    });

    menuCloseButton?.focus();

    if (body) {
        if (innerWidth > clientWidth) {
            body.style.paddingRight = '15px';
        } else {
            body.style.paddingRight = '';
        }
    }

    if (window.innerWidth >= desktopWidth) {
        setTimeout(() => {
            menu?.classList.remove('menu_locked');
        }, 300);
    }
};

overlay?.addEventListener('click', closeMenu);
menuCloseButton?.addEventListener('click', closeMenu);
menuOpenButton?.addEventListener('click', openMenu);

const currentUrl = new URL(window.location.href);

if (headerBody && currentUrl.pathname !== '/search/' && currentUrl.pathname !== '/newsletter/') {
    headerBody.focus();
}

document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
        closeMenu();
    }
});

export {};
