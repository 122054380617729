import { checkIfCookieEnabled } from '../utils/cookie';
import { insertTemplate } from '../utils/dom';
import { heightCSSVariableName } from './cookie-policy';

if (!checkIfCookieEnabled()) {
    insertTemplate(document.querySelector<HTMLElement>('#cookie-off-template'));

    const cookieOf = document.querySelector('[data-js-cookies-off]');
    const cookieOfButton = cookieOf?.querySelector('[data-js-cookie-off-button]');

    cookieOf?.classList.add('cookie-policy--is-open');

    cookieOfButton?.addEventListener('click', (event) => {
        event.preventDefault();

        document.documentElement.style.setProperty(heightCSSVariableName, '0px');
        cookieOf?.classList.remove('cookie-policy--is-open');
    });
}

export {};
