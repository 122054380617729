// @ts-ignore
// eslint-disable-next-line import/extensions
import Routing from './routing';

// @ts-ignore
const HCAPTCHA_SITE_KEY = ENV_HCAPTCHA_SITE_KEY as string;
const COMMENTS_TERMS_LINK = Routing.generate('terms-conditions');
const COMMENTS_PRIVACY_LINK = Routing.generate('privacy-policy');
const COOKIE_PREFIX = 'cn_t';
const BI_LINK_REGEX_FOR_TRACKING = /bi\.cybernews\.com/i;
const COMMENTS_API_LINK = '/api/add-comment/';
const GET_YOUR_FREE_CONSULTATION_API_LINK = '/api/send/get-your-free-consultation/';
const BUSSINESS_DIGITAL_INDEX_SEARCH = '/api/business-digital-index/';
const RANSOM_API = 'https://ransomlooker.cybernews.com/';
const PASSLEAK_API = 'https://dataleak.cybernews.com/';

export {
    HCAPTCHA_SITE_KEY,
    COOKIE_PREFIX,
    BI_LINK_REGEX_FOR_TRACKING,
    COMMENTS_PRIVACY_LINK,
    COMMENTS_TERMS_LINK,
    COMMENTS_API_LINK,
    GET_YOUR_FREE_CONSULTATION_API_LINK,
    RANSOM_API,
    PASSLEAK_API,
    BUSSINESS_DIGITAL_INDEX_SEARCH,
};
